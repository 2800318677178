<template lang="pug" src="./Dashboard.pug">
</template>
<script>

  import { mapActions } from 'vuex'
  import { mapGetters } from 'vuex'
  import moment from 'moment-timezone'

  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3' // para esconder o recaptcha do Login
  Vue.use(VueReCaptcha, { siteKey: '6LfaK4IaAAAAAFyL_mC2cTeLgkcdpCOUVJn8QkOr' })

  export default{
    name: 'Painel',
    data: () => ({
      chartOptions: {
        chart: {
          id: 'faturamento',
          type: 'bar',
          stacked: true
        },
        xaxis: {
          categories: []
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#5E737E']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
      },
      series: [{
        name: 'series-1',
        data: []
      }]

    }),
    computed: {
      ...mapGetters(['user']),
      dash() {
        return this.$store.state.dash.dash
      }
    },
    methods: {
      ...mapActions(['getDash']),
      moment: function () {
          return moment()
      },
      async getDashData(){
        await this.getDash()
        this.updateChart()
      },

      reloadDash(){
        this.getDashData()
      },

      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      updateChart(){
        // monta o gráfico
        this.chartOptions = {...this.chartOptions, ...{
          xaxis: {
              categories: this.dash.chart.paid.map(({ date }) => moment(date).format('DD/MM/YYYY'))
          }
        }}

        if(this.$refs.dashChart != undefined){
          this.$refs.dashChart.updateOptions({
            series: [
              {
                name: 'Recebido',
                data: this.dash.chart.paid.map(({ total }) => parseFloat(total.toFixed(2)))
              },
              {
                name: 'Aguardando',
                data: this.dash.chart.waiting.map(({ total }) => parseFloat(total.toFixed(2)))
              }
            ]
          })
        }
      }
    },
    mounted(){

      // setTimeout(() => {
      //   const recaptcha = this.$recaptchaInstance
      //   recaptcha.hideBadge()
      // }, 1500);

      this.getDashData()
    }
  }
</script>
<style>
  .reloadBtnAbsolute{
    position: fixed;
    bottom: 20px;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
</style>
